<template>
  <div class="a-flex-rfsfs">
    <div v-show="label" class="s-search-label a-flex-rfec" style="width: 82px">
      <span v-if="notnull" class="a-c-red a-fs-14">*</span>
      <div>{{ label }}</div>
    </div>
    <div class="a-ml-20">
      <el-upload
        ref="upload"
        :action="''"
        :on-remove="onRemove"
        :on-change="onChange"
        :accept="acceptStr"
        :auto-upload="false"
        :limit="1"
      >
        <el-button style="border: 1px solid #dadde0; border-radius: 4px" icon="el-icon-upload2">
          上传文件
        </el-button>
      </el-upload>
    </div>
  </div>
</template>

<script>
export default {
  name: "UploadFile",

  props: {
    value: {
      required: false,
      default: {},
    },
    label: {
      type: String,
      default: "",
    },
    notnull: {
      type: Boolean,
      default: false,
      required: false,
    },
  },

  data() {
    return {};
  },

  computed: {
    acceptStr() {
      return [".xls", ".xlsx"].join(",");
    },
  },

  watch: {
    value(val) {
      if (!val) {
        this.$refs.upload.clearFiles();
      }
    },
  },

  methods: {
    onRemove() {
      this.$emit("input", "");
    },

    onChange(file) {
      if (file) {
        this.$emit("input", file.raw);
      }
    },
  },
};
</script>
