import request from "@/plugins/axios";

class DeviceApi {
  batchImportURL = "/api/device/device/batchImport";
  batchImportEffectKey = `post${this.getUnderCompanyURL}`;
  batchImport = (data) => {
    return request({
      url: this.batchImportURL,
      method: "post",
      data: data,
    });
  };
}

export const deviceApi = new DeviceApi();
