<template>
  <el-dialog
    class="dialog-form"
    title="批量导入"
    width="20%"
    :visible.sync="dialogVisible"
    :before-close="onClickClose"
  >
    <el-form ref="form" :model="form" :rules="rules">
      <div class="a-flex-cfsfs">
        <el-form-item prop="file">
          <upload-file v-model="form.file" label="上传表格" notnull></upload-file>
        </el-form-item>
        <el-form-item prop="stationId">
          <le-select-local-search
            v-model="form.stationId"
            label="绑定站点"
            :options="stationList"
            placeholder="请选择站点"
          />
        </el-form-item>
      </div>
    </el-form>
    <span slot="footer">
      <el-button @click="onClickClose">取 消</el-button>
      <el-button type="primary" @click="onClickSubmit">确 定</el-button>
    </span>
  </el-dialog>
</template>

<script>
import { companyStationApi } from "@/services/apis/company/station";
import { deviceApi } from "@/services/apis/device/index";

import UploadFile from "./upload-file.vue";

export default {
  name: "BatchImportDialog",

  components: {
    UploadFile,
  },

  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      stationList: [],
      form: {
        stationId: "",
        file: "",
      },
      rules: {
        file: [{ required: true, message: "请上传文件", trigger: "change" }],
      },
    };
  },

  computed: {
    dialogVisible: {
      get() {
        return this.visible;
      },
      set(val) {
        this.$emit("update:visible", val);
      },
    },
  },

  watch: {
    "form.file"(val) {
      if (val) {
        this.$refs.form.clearValidate(["file"]);
      }
    },
  },

  created() {
    this.getStationList();
  },

  methods: {
    async getStationList() {
      const list = await companyStationApi.selectList();
      this.stationList = list.map((item) => {
        return {
          value: item.id,
          label: item.name,
        };
      });
    },

    onClickClose() {
      this.dialogVisible = false;
      this.form = {
        stationId: "",
        file: "",
      };
      this.$refs.form.clearValidate();
    },

    onClickSubmit() {
      this.$refs.form.validate(async (validate) => {
        if (validate) {
          const formData = new FormData();
          formData.append("file", this.form.file);
          formData.append("stationId", this.form.stationId);
          await deviceApi.batchImport(formData);
          this.onClickClose();
          this.$emit("submit");
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog-form {
  .el-form-item__error {
    left: 100px !important;
  }
}
</style>
