import request from "@/plugins/axios";

class CcompanyStationApi {
  selectListURL = "/api/company/platform/station/list-for-select";
  selectListEffectKey = `get${this.selectListURL}`;
  selectList = () => {
    return request({
      url: this.selectListURL,
      method: "get",
    });
  };
}

export const companyStationApi = new CcompanyStationApi();
