<template>
  <div class="app-body">
    <div class="a-flex-rsbc a-ptb-15">
      <span class="a-fs-16 a-fw-700">设备管理</span>
      <div class="a-flex-rcc">
        <el-dropdown placement="bottom-start" @command="handlerOperationDev">
          <el-button class="s-btn-batch">
            批量操作
            <i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="item in optionsBatch"
              :key="item.value"
              :command="item.value + '#'"
              :value="item.value"
            >
              {{ item.label }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
        <le-export-btn :page-param="pageParam"></le-export-btn>
        <el-dropdown placement="bottom-start" @command="handlerAddDev">
          <el-button icon="el-icon-plus" type="primary" class="s-btn-add">设备</el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="item in optionsDev"
              :key="item.value"
              :command="item.value"
              :value="item.value"
            >
              {{ item.label }}
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>
      </div>
    </div>
    <el-card class="el-main">
      <le-search-form
        id="leSearch"
        :can-pack-up="true"
        @reset="handlerRest"
        @search="handlerSearch"
      >
        <le-company-under-select
          v-model="pageParam.params.agencyCompanyId"
          label="加盟商"
        ></le-company-under-select>
        <le-date-range
          label="安装时间"
          :min-date.sync="pageParam.params.installStartDate"
          :max-date.sync="pageParam.params.installEndDate"
        />
        <le-input v-model="pageParam.params.searchKey" type="number" label="设备编号" />
        <le-select-remote-search
          v-model="pageParam.params.deviceTypeId"
          label="设备类型"
          :options="optionsDeviceType"
          placeholder="请选择（可搜索）"
        />
        <le-select-local-search
          v-model="pageParam.params.status"
          label="设备状态"
          :options="optionsDeviceStatus"
        />
        <le-select-local-search
          v-model="pageParam.params.canUse"
          label="启用状态"
          :options="optionsDeviceUseStatus"
        />
      </le-search-form>
      <le-pagview :page-param="pageParam" @setData="setTableData">
        <el-table
          ref="deviceList"
          v-sticky="{ top: 0, parent: '.el-card__body' }"
          :data="tableData"
          :row-key="getRowKey"
          :highlight-current-row="true"
          style="width: 100%"
          @selection-change="handleSelectionChange"
          @select="selectItem"
          @select-all="selectAll"
        >
          <el-table-column
            type="selection"
            align="center"
            width="55"
            class="device-checkBox"
          ></el-table-column>
          <el-table-column prop="deviceCode" label="设备号" min-width="150">
            <template slot-scope="{ row }">
              <span class="a-c-blue font-point" @click="getDeviceInfo(row)">
                {{ row.deviceCode ? row.deviceCode : "-" }}
              </span>
            </template>
          </el-table-column>
          <el-table-column prop="stationName" label="所属站点" min-width="240">
            <template slot-scope="{ row }">
              <span>{{ row.stationName ? row.stationName : "-" }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="companyName" label="所属加盟商" min-width="200">
            <template slot-scope="{ row }">
              <span>{{ row.companyName ? row.companyName : "-" }}</span>
            </template>
          </el-table-column>
          <el-table-column prop="onlineStatus" label="设备状态" min-width="100">
            <template slot-scope="{ row }">
              <div class="a-flex-rfsc">
                <div v-if="row.onlineStatus == 1" class="online-dev"></div>
                <div v-if="row.onlineStatus == 0" class="offline-dev"></div>
                <div v-if="row.onlineStatus == 2" class="damage-dev"></div>
                <span class="a-ml-5">{{ row.onlineStatus | initDic(optionsDeviceStatus) }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="插座信号" min-width="120">
            <template slot-scope="{ row }">
              <div>
                <span v-if="row.csq && row.csq >= 18" class="a-fs-14 c29CC29">强(-1db)</span>
                <span v-else-if="row.csq && row.csq >= 11 && row.csq < 18" class="a-fs-14 cFFA900">
                  中(-50db)
                </span>
                <span v-else-if="row.csq && row.csq < 11" class="a-fs-14 cFF3B30">弱(-98db)</span>
                <span v-else class="a-fs-14 a-c-normal">无信号</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="name" label="启用/禁用" min-width="104">
            <template slot-scope="scope">
              <el-switch
                v-model="scope.row.canUse"
                :active-value="1"
                :inactive-value="0"
                active-color="#007AFF"
                inactive-color="#F56C6C"
                @change="devUseOrNotUse(scope.row, scope.$index)"
              ></el-switch>
            </template>
          </el-table-column>
          <el-table-column prop="deviceTypeText" label="设备类型" min-width="120"></el-table-column>
          <el-table-column prop="name" label="插槽使用情况" min-width="320">
            <template slot="header">
              <div class="a-flex-rfsc">
                <span>插槽使用情况</span>
                <el-tooltip popper-class="c-tooltip" placement="top">
                  <div slot="content" class="a-flex-rfsc">
                    <div class="a-flex-rcc a-fw-400">
                      <div class="device-off"></div>
                      <span class="a-fs-12">空闲</span>
                    </div>
                    <div class="a-flex-rcc a-fw-400 a-ml-24">
                      <div class="device-online"></div>
                      <span class="a-fs-12">充电中</span>
                    </div>
                  </div>
                  <img
                    style="margin-left: 4px; margin-top: -2px"
                    src="../../assets/icon/instruct-icon.png"
                    class="a-wh-16"
                  />
                </el-tooltip>
              </div>
            </template>
            <template slot-scope="{ row }">
              <div style="flex-wrap: wrap">
                <div
                  v-for="(val, key, index) in row.slotUseStatus"
                  :key="index"
                  class="slotUseStatus a-fs-12"
                  :class="index < 6 && Object.keys(row.slotUseStatus).length > 6 ? 'mb8' : ''"
                >
                  <span
                    :class="[
                      val ? 'slotUsing' : 'slotNotUse',
                      index != 5 && index != Object.keys(row.slotUseStatus).length - 1
                        ? 'grayBorder'
                        : '',
                    ]"
                  >
                    {{ key }}
                  </span>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="lastActiveTime"
            label="上次活跃时间"
            min-width="200"
          ></el-table-column>
          <el-table-column prop="createTimeText" label="创建时间" min-width="200"></el-table-column>
          <el-table-column prop="name" label="操作" min-width="112" fixed="right">
            <template slot-scope="scope">
              <el-tooltip class="item" effect="dark" content="设备详情" placement="top">
                <img
                  src="../../assets/icon/option-detail.png"
                  class="a-wh-16"
                  @click="getDeviceInfo(scope.row)"
                />
              </el-tooltip>
              <el-tooltip class="item a-ml-12" effect="dark" content="二维码" placement="top">
                <img
                  src="../../assets/icon/qrcode.png"
                  class="a-wh-16"
                  @click="$refs['devQrCode'].qrVal = scope.row.qrcodeUrl"
                />
              </el-tooltip>
              <el-tooltip class="item a-ml-12" effect="dark" content="更多操作" placement="top">
                <el-dropdown placement="bottom-start" @command="handlerOperationDev">
                  <img src="../../assets/icon/more-operation.png" class="a-wh-16" />
                  <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item
                      v-for="item in optionsMoreOperation"
                      :key="item.value"
                      :disabled="!scope.row.stationName && item.value == 1"
                      :command="item.value + '#' + scope.$index"
                      :value="item.value"
                    >
                      {{ item.label }}
                    </el-dropdown-item>
                  </el-dropdown-menu>
                </el-dropdown>
              </el-tooltip>
            </template>
          </el-table-column>
        </el-table>
        <template slot="footerLabel" slot-scope="scope">
          <div v-if="scope.val != -1" class="a-flex-rfsc">
            <span class="a-fs-12 a-c-normal">筛选出</span>
            <span class="a-fs-16 a-c-master a-fw-b a-plr-7">{{ scope.val }}</span>
            <span class="a-fs-12 a-c-normal">台设备</span>
          </div>
        </template>
      </le-pagview>
    </el-card>
    <dev-qrcode ref="devQrCode" />
    <dev-param-set ref="paramSet" @paramsSet="paramsSetResult"></dev-param-set>
    <dev-param-result ref="paramResult" @resetParams="resetParams"></dev-param-result>
    <dev-reboot-result ref="rebootResult" :table-data="rebootResult"></dev-reboot-result>
    <a-poppup ref="aPoppup">
      <div class="pop-content a-flex-rcc">
        <div class="progress">
          <div class="progress-item"></div>
        </div>
      </div>
    </a-poppup>
    <batch-import-dialog
      :visible.sync="dialogBatchImportVisible"
      @success="importDevSuccess"
    ></batch-import-dialog>
  </div>
</template>

<script>
import APoppup from "../components/poppup/a-poppup.vue";
import DevParamResult from "./child/dev-param-result.vue";
import DevParamSet from "./child/dev-paramSet.vue";
import DevQrcode from "./child/dev-qrcode.vue";
import DevRebootResult from "./child/dev-reboot-result.vue";
import BatchImportDialog from "./components/batch-import-dialog.vue";

export default {
  name: "DeviceList",
  components: {
    DevQrcode,
    DevParamSet,
    DevParamResult,
    APoppup,
    DevRebootResult,
    BatchImportDialog,
  },
  filters: {
    initDic(value, dic) {
      if (value === "" || value === undefined || value === null) {
        return "";
      } else {
        for (let i = 0; i < dic.length; i++) {
          if (value == dic[i].value) {
            return dic[i].label;
          }
        }
      }
    },
  },
  data() {
    return {
      tableData: [],
      selectData: [], //选中的列表
      pagenum: 1, //当前页码
      pageParam: {
        url: this.$Config.apiUrl.getDeviceList,
        method: "post",
        params: {
          exportFlag: false,
          searchKey: "", // 设备码
          agencyCompanyId: "", // 加盟商
          installStartDate: "",
          installEndDate: "",
          deviceTypeId: "",
          status: "", //设备状态
          canUse: "", //启用状态
        },
        freshCtrl: 1,
      },
      optionsDeviceType: {
        url: this.$Config.apiUrl.getDeviceType,
        method: "post",
        params: {},
        labelKey: "name",
        valueKey: "id",
        searchKey: "key",
      },
      optionsDeviceStatus: [
        {
          label: "离线",
          value: 0,
        },
        {
          label: "在线",
          value: 1,
        },
        {
          label: "故障",
          value: 2,
        },
      ],
      optionsDeviceUseStatus: [
        {
          label: "启用",
          value: 1,
        },
        {
          label: "禁用",
          value: 0,
        },
      ],
      optionsBatch: [
        {
          label: "解绑",
          value: 1,
        },
        {
          label: "远程重启",
          value: 2,
        },
        {
          label: "启用",
          value: 3,
        },
        {
          label: "禁用",
          value: 4,
        },
        {
          label: "参数下发",
          value: 5,
        },
      ],
      optionsMoreOperation: [
        {
          label: "解绑",
          value: 1,
        },
        {
          label: "远程重启",
          value: 2,
        },
        {
          label: "参数下发",
          value: 5,
        },
        {
          label: "编辑",
          value: 6,
        },
      ],
      optionsDev: [
        {
          label: "XLS批量导入",
          value: 1,
        },
        {
          label: "新增设备",
          value: 2,
        },
        {
          label: "下载模板",
          value: 3,
        },
      ],
      qrVal: "",
      rebootResult: [], //重启结果
      fileUrl: "", //文件导出url
      deviceXLS: "", // 文件导入
      dialogBatchImportVisible: false,
    };
  },
  methods: {
    //获取设备列表
    setTableData(data, fileUrl) {
      this.tableData = data;
      this.fileUrl = fileUrl;
    },
    handlerRest() {
      this.pageParam.params = {
        searchKey: "", // 设备码
        agencyCompanyId: "", // 加盟商
        installStartDate: "",
        installEndDate: "",
        deviceTypeId: "",
        status: "", //设备状态
        canUse: "", //启用状态
      };
      this.handlerSearch();
    },
    handlerSearch() {
      this.pageParam.freshCtrl++;
    },
    getRowKey(row) {
      return row.deviceCode;
    },
    // 单选
    selectItem() {},
    // 全选
    selectAll() {},
    handleSelectionChange(selection) {
      this.selectData = selection;
    },
    devUseOrNotUse(datas, index) {
      if (datas.canUse == 0) {
        this.handlerOperationDev("4#" + index);
      } else if (datas.canUse == 1) {
        this.handlerOperationDev("3#" + index);
      }
    },
    // 批量操作表格
    handlerOperationDev(commands) {
      const command = commands.split("#")[0];
      const index = commands.split("#")[1];
      let arr = this.selectData.length ? this.selectData : [];
      let devparamType = "set";
      if (index) {
        // 如果有index值,则是表格单独操作, 否则为批量操作
        arr = [this.tableData[index]];
        devparamType = "single";
      }
      if (!arr.length) {
        this.$message.warning("请至少选择一台设备");
        return;
      }
      switch (command) {
        case "1":
          // 解绑
          this.$confirm(
            "解绑后设备将自动禁用，绑定新站点后可再次启用，是否确认将所选设备解除绑定",
            "温馨提示",
            {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            }
          ).then(() => {
            const reqData = [];
            arr.map((item) => {
              reqData.push(item.deviceCode);
            });
            this.unbindDev(reqData);
          });
          break;
        case "2":
          // 重启
          this.$confirm("是否确认远程重启所选设备?", "温馨提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }).then(() => {
            const reqData = [];
            arr.map((item) => {
              reqData.push(item.deviceCode);
            });
            this.batchRebootDev(reqData);
          });
          break;
        case "3":
          // 启用
          this.$confirm("是否确认启用所选设备?", "温馨提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              const reqData = [];
              arr.map((item) => {
                reqData.push(item.deviceCode);
              });
              this.setCanUse(reqData);
            })
            .catch(() => {
              this.tableData[index].canUse = this.tableData[index].canUse == 0 ? 1 : 0;
            });
          break;
        case "4":
          // 禁用
          this.$confirm("是否确认禁用所选设备?", "温馨提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          })
            .then(() => {
              const reqData = [];
              arr.map((item) => {
                reqData.push(item.deviceCode);
              });
              this.setNotUse(reqData);
            })
            .catch(() => {
              this.tableData[index].canUse = this.tableData[index].canUse == 0 ? 1 : 0;
            });
          break;
        case "5":
          // 参数下发
          this.devParamSet(arr, devparamType);
          break;
        case "6":
          // 设备编辑
          this.$router.push({
            path: "/device/device-edit",
            query: {
              deviceCode: arr[0].deviceCode,
            },
          });
          break;
        default:
          break;
      }
    },
    // 解绑设备
    unbindDev(datas) {
      this.$Axios
        ._post({
          url: this.$Config.apiUrl.batchUnBindDevice,
          method: "post",
          params: {
            deviceCodes: datas,
          },
        })
        .then((res) => {
          if (res.result.code == 0) {
            this.$message.success("操作成功");
            this.pageParam.freshCtrl++; // 重新请求表格数据
          } else {
            this.$message.error(res.result.message);
          }
        });
    },
    // 新增设备
    handlerAddDev(command) {
      if (command == 1) {
        this.dialogBatchImportVisible = true;
      } else if (command == 2) {
        this.$router.push({
          path: "/device/device-add",
        });
      } else if (command == 3) {
        window.open(
          "https://hichar-file.oss-cn-shanghai.aliyuncs.com/pc/%E8%AE%BE%E5%A4%87%E5%AF%BC%E5%85%A5.xlsx",
          "_blank"
        );
      }
    },
    // 参数下发
    devParamSet(datas, type = "set") {
      this.$refs.paramSet.devDatas = datas;
      this.$refs.paramSet.setType = type;
      this.$refs.paramSet.deviceId = type == "single" ? datas[0].deviceId : "";
      this.$refs.paramSet.dialogVisible = true;
    },
    // 启用设备
    setCanUse(datas) {
      this.$Axios
        ._post({
          url: this.$Config.apiUrl.batchSetDeviceCanUse,
          method: "post",
          params: {
            deviceCodes: datas,
          },
        })
        .then((res) => {
          if (res.result.code == 0) {
            this.$message.success("操作成功");
            this.pageParam.freshCtrl++; // 重新请求表格数据
          } else {
            this.$message.error(res.result.message);
          }
        });
    },
    setNotUse(datas) {
      this.$Axios
        ._post({
          url: this.$Config.apiUrl.batchSetDeviceStopUse,
          method: "post",
          params: {
            deviceCodes: datas,
          },
        })
        .then((res) => {
          if (res.result.code == 0) {
            this.$message.success("操作成功");
            this.pageParam.freshCtrl++; // 重新请求表格数据
          } else {
            this.$message.error(res.result.message);
          }
        });
    },
    // 禁用设备
    getDeviceInfo(datas) {
      this.$router.push({
        path: "/device/device-info",
        query: {
          deviceCode: datas.deviceCode,
        },
      });
    },
    // 远程重启
    batchRebootDev(datas) {
      this.showPop();
      this.$Axios
        ._post({
          url: this.$Config.apiUrl.batchReboot,
          method: "post",
          noShowLoading: true,
          params: {
            deviceCodes: datas,
          },
        })
        .then((res) => {
          this.$refs["aPoppup"].close();
          if (res.result.code == 0) {
            this.rebootResult = res.result.data;
            this.$refs["rebootResult"].dialogVisible = true;
            this.pageParam.freshCtrl++; // 重新请求表格数据
          } else {
            this.$message.error(res.result.message);
          }
        })
        .catch(() => {
          this.$refs["aPoppup"].close();
        });
    },
    // 显示弹窗
    showPop() {
      this.$refs["aPoppup"].open({
        title: "设备重启中",
        subTitle: "预计等待时间1分30秒",
        showConfirm: true,
        showCancel: false,
        confirmText: "确认",
        cancelText: "取消",
      });
    },
    paramsSetResult(datas) {
      this.$refs.paramResult.tableData = datas;
      this.$refs.paramResult.dialogVisible = true;
    },
    // 下发参数重试
    resetParams(datas) {
      this.devParamSet(datas, "reset");
    },
    exportfile() {
      if (!this.fileUrl) {
        this.$message.warning("暂无可导出文件");
        return;
      }
      this.$confirm("是否确认导出文件？", "温馨提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$Utils.downloadUrlFile(this.fileUrl, "设备列表");
      });
    },
    importDevSuccess() {
      this.$message.success("导入成功");
      this.pageParam.freshCtrl++;
    },
  },
};
</script>

<style lang="scss" scoped>
.device-off {
  width: 6px;
  height: 6px;
  background: #909499;
  border-radius: 50%;
  margin-right: 6px;
}
.device-online {
  width: 6px;
  height: 6px;
  background: #29cc29;
  border-radius: 50%;
  margin-right: 6px;
}
.slotUseStatus {
  width: 48px;
  height: 24px;
  min-height: 24px !important;
  background: #f4f6f9;
  // margin-bottom: 8px;
  display: inline-block !important;
  span {
    display: inline-block !important;
    width: 48px;
    height: 24px;
    text-align: center;
    line-height: 24px;
    // margin-top: 4px;
  }
  .grayBorder {
    border-right: 1px solid #e3e3e3;
  }
  .slotUsing {
    color: #29cc29;
  }
  .slotNotUse {
    color: #909499;
  }
}
.c29CC29 {
  color: #29cc29;
}
.cFFA900 {
  color: #ffa900;
}
.cFF3B30 {
  color: #ff3b30;
}
.width280 {
  width: 280px;
}
.progress {
  width: 205px;
  height: 7px;
  border-radius: 4px;
  margin: 13px 0 24px;
  background: #b9b9b9;
}
.progress-item {
  width: 100%;
  height: 7px;
  border-radius: 4px;
  background: #007aff;
  animation: progressPop 10s;
  -webkit-animation: progressPop 5s; /* Safari and Chrome */
}
/* 动画 */
@keyframes progressPop {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

@-webkit-keyframes progressPop /* Safari and Chrome */ {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
.s-btn-exp {
  margin: 0 14px;
}
.mb8 {
  margin-bottom: 8px;
}
.online-dev {
  width: 7px;
  height: 7px;
  min-height: 7px !important;
  border-radius: 50%;
  background: #32cf4c;
}
.offline-dev {
  width: 7px;
  height: 7px;
  min-height: 7px !important;
  border-radius: 50%;
  background: #b9b9b9;
}
.damage-dev {
  width: 7px;
  height: 7px;
  min-height: 7px !important;
  border-radius: 50%;
  background: #ff3b30;
}

/deep/ .el-table-column--selection .cell {
  justify-content: center !important;
}
</style>
